.cardContainer {
    background-color: white;
    width: 100%;
    height: 18.85rem;
    margin: auto;
    position: relative;
    transition: 1s;
    overflow: hidden;
    border-radius: 0 0 .5rem .5rem;
}

/*media query for anything bigger than 1300px*/
@media (min-width: 1600px) {
    .cardContainer {
        width: 85%; 
    }
}

svg {
    height: 100%;
}

svg path:nth-last-of-type(2) {
    fill: var(--about-us-color);
}

svg path:last-of-type {
    fill: var(--background-color);
}

.noteContainer {
    position: absolute;
    bottom: 1%;
    right: 2%;
    transition: 1s;
    overflow: hidden;
    padding: 1%;
    height: 90%;
}

.noteContainer:hover {
    transition: 1s;
    height: 100%;
    right: 2%;
    bottom: 0;
}

.noteText {
    font-family: 'EB Garamond';
    font-size: 1.2em;
    line-height: 3rem;
    text-indent: 3ch;
    /* disable highlight */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@keyframes fadeInOutAnimation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* this card image should have styleing that makes it fade to white on the right side */
.cardImage {
    animation: fadeInOutAnimation 10s infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    transition: 1s;
}

.cardImageContainer {
    transition: 1s;
    width: 80%;
    overflow: hidden;
}

.cardImageCover {
    /* gradient from transparent to white LEFT to RIGHT */
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    /* gradient from transparent to white LEFT to RIGHT */
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    transition: 1s;
}

.cardTitle {
    font-family: 'EB Garamond';
    color: var(--background-color);
    font-size: 5em;
    font-weight: bold;
    margin: 1.3rem;
    transition: 1s;
    position: absolute;
    top: 12.9rem;
    left: 0;
}

@media only screen and (max-width: 900px) {
    .cardTitle {
        font-size: 3em;
        top: 14.85rem
    }

    .noteContainer {
        height: 80%;
        bottom: 15%;
    }
}