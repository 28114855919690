.contactSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #f5f5f5;
    transition: 1s;
    margin: 0 auto;
    padding: 2%;
    align-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    border-radius: .5rem;
}

.contactSection h1 {
    font-size: 2.5rem;
    margin-bottom: 0rem;
    color: #333;
    font-family: Raleway, sans-serif;
    font-weight: lighter;
    /* disable highlighting */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.contactSection img {
    width: 3rem;
    height: 3rem;
    transition: .5s;
}

.contactSection img:hover {
    width: 4rem;
    height: 4rem;
    transition: .5s;
}

.contactSection span {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    right: 3rem;
}

.socialMediaContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 40%;
    right: 2rem;
}

.socialMediaContainer a {
    margin: 1rem;
}

@media only screen and (max-width: 768px) {
    .contactSection h1 {
        font-size: 2rem;
    }
}