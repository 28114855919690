.container {
    background-color: var(--background-color);
    align-content: center;
    transition: 1s;
    margin-bottom: 1rem;;
}

/* media query for anything bigger than 1300px */
@media (min-width: 1600px) {
    .container {
        width: 66%;
        transition: 1s;
        margin-left: 17%;
        margin-right: 17%;
    }
}

.spanContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headerMainText {
    color: white;
    font-weight: 100;
    font-family: Raleway, sans-serif;
    font-size: 1.5em;
    align-self: center;
}

.linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.link{
    color: white;
    font-weight: 100;
    font-family: Raleway, sans-serif;
    font-size: 1.5em;
    align-self: center;
    text-decoration: none;
    transition: 1s;
    margin-right: 1.5rem;
}

.link:hover {
    color: var(--link-hover-color); /* I really like this color */
    font-weight: 100;
    transition: 1s;
}